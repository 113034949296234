<template>
  <div class="map-container">
    <!-- <div class="map-crumbs">
      <router-link :to="{ name: 'culture_homepage' }">首页 / </router-link>
      <div>场馆预定</div>
    </div> -->
    <div class="map-info">
      <div class="tab-secondNav" v-if="secondNav.length > 0" style="margin-bottom:0.2rem;">
        <ul class="tab-secondNav-choose-item">
          <li
            v-for="(item, i) in secondNav"
            :key="i"
            :class="{ tabActivesecondNav: cur2 == item.id }"
            @click="changeSecondNav(item, i)"
          >
            {{ item.name }}
            <span :class="{ tabActivesecondNavSpan: cur2 == item.id }">
              <span :class="{ tabActivesecondNavSubSpan: cur2 == item.id }">
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div class="culture-switch-type" v-if="mapList.length>0">
        <div class="culture-switch-type-title">选择场馆：</div>
        <div class="culture-switch-type-content">
          <div
            class="culture-switch-type-content-li"
            :class="{
              'culture-switch-type-content-li-active': cur4 == item.id&&item.name.length <= 4,
              imgType: item.name.length > 4,
              'imgType-active': cur4 == item.id&&item.name.length > 4,
            }"
            v-for="(item, index) in mapList"
            :key="'categoryList' + index"
            @click="handleChangeValue1(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="culture-switch-type" v-if="categoryList.length>0">
        <div class="culturek-switch-type-title"></div>
        <div class="culture-switch-type-content">
          <div
            class="culture-switch-type-content-li"
            :class="{
              'culture-switch-type-content-li-active': cur == item.id&&item.label_name.length <= 4,
              imgType: item.label_name.length > 4,
              'imgType-active': cur == item.id&&item.label_name.length > 4,
            }"
            v-for="(item, index) in categoryList"
            :key="'categoryList' + index"
            @click="handleChangeValue(item.id)"
          >
            {{ item.label_name }}
          </div>
        </div>
      </div>
      <!-- 类型的tab切换中的假内容 -->
      <div class="map-tab-content" v-if="tabMain && tabMain.length !== 0" style="margin-top: 0.2rem;">
        <ul class="maplist-ul">
          <li
            class="maplist-ul-li"
            v-for="(item, index) in tabMain"
            :key="'maplist' + index"
            @click="toNewPage(item)"
          >
            <div class="maplist-ul-li-banner">
              <img
                style="width: 100%; height: 100%"
                :src="item.space_pic"
                alt=""
              />
              <!-- <div
                class="maplist-ul-li-banner-total"
                v-if="item.is_open == 1"
              >
                可预约
              </div> -->
            </div>
            <div class="maplist-ul-li-bottom">
              <div class="maplist-ul-li-bottom-title">
                <!-- {{ item.space_name }} -->
                <div class="name">
                                {{item.space_name}}
                            </div>
                            <div class="column"></div>
                            <div class="area">
                                {{item.measure_area}}m²
                            </div>
              </div>
              <div class="maplist-ul-li-bottom-map">
                            所属场馆：{{item.name}}
                        </div>
                        <div class="maplist-ul-li-bottom-reserve" v-if="item.is_open==1">
                          <!-- {{item.available_reserve}} -->
                          可预订
                        </div>
            </div>
            <img
              class="maplist-li-selected"
              src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
              alt=""
            />
          </li>
        </ul>
        <div class="map-tab-show-more" v-if="showMore">
          <div @click="getMoreList">查看更多</div>
        </div>
      </div>

      <!-- 没有内容显示的页面 -->
      <Nocontent v-else title="暂无场馆，请持续关注哦～"></Nocontent>
    </div>
  </div>
</template>

<script>
import { getVenueMap, getSpace } from "@/api/map/map.js";
import Nocontent from "@/views/components/Nocontent";
import { navChildList } from "@/api/header/header";
export default {
  data() {
    return {
      current: 0,
      cur: 0,//标签选择
      limit: 6,
      page: 1,
      tabMain: [],
      latitude: 0,
      longitude: 0,
      showMore: true,
      column_id: "",
      cid: "",
      cur2: 0,//二级导航选择
      cur3: 0,//状态筛选
      cur4: 0,//场馆选择
      secondNav: [],
      categoryList:[],
      mapList:[],
      aid: process.env.VUE_APP_AID,
    };
  },
  created() {},
  mounted() {
    window.scroll(0, 0);
    this.column_id = this.$route.query.column_id;
    this.cid = this.$route.query.cid;
    if (this.cid) {
      this.cur2 = this.cid;
    }
    this.getMapList();
    this.getChildNav();
    if (this.$route.query.venue_id) {
      this.current = this.$route.query.venue_id;
    }
  },
  components: {
    Nocontent,
  },
  watch:{
      $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.cur4 = 0;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getMapList();
      this.getChildNav();
    },
  },
  methods: {
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };
      navChildList(params).then((res) => {
        if (res.code == 0) {
          this.secondNav = res.data;
        }
      });
    },
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
            this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
        //   let routeUrl = this.$router.resolve({
        //     path: `/${item.tpl_code}`,
        //     query: {
        //       id: this.$route.query.id,
        //       cid: item.id,
        //       column_id: item.column_id,
        //     },
        //   });
        //   window.open(routeUrl.href, "_blank");
        } else {
            this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
        //   let routeUrl = this.$router.resolve({
        //     path: `/${item.tpl_code}/detail`,
        //     query: {
        //       tid: item.content_id,
        //       id: this.$route.query.id,
        //       cid: item.id,
        //       column_id: item.column_id,
        //     },
        //   });
        //   window.open(routeUrl.href, "_blank");
        }
      }
    },
    handleChangeValue(i) {
      this.page = 1;
      this.cur = i;
      this.getSpaceList();
    },
    handleChangeValue1(i) {
      this.page = 1;
      this.cur4 = i;
      this.getSpaceList();
    },
    getMapList() {
      let params = {
        aid: process.env.VUE_APP_AID,
      };
      getVenueMap(params).then((res) => {
        if (res.code == 0) {
          this.mapList = res.data;
          if(this.mapList.length>0){
              this.mapList.unshift({
                id: 0,
                name: "全部",
            });
          }
        //   else if(this.mapList.length==1){
        //     //   this.cur4 = this.mapList[0].id;
        //   }
          
          this.getSpaceList();
        }
      });
    },
    getSpaceList() {
      let params = {
        aid: process.env.VUE_APP_AID,
        column_id: this.column_id,
        venue_id: this.cur4,
        page: this.page,
        limit: this.limit,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = parseInt(this.cur);
      }
      getSpace(params).then((res) => {
        if (res.code == 0) {
          if (res.data.datalist.length == 0) {
            this.showMore = false;
          }
          if (
            res.data.datalist.length < res.data.datalist ||
            res.data.page == Math.ceil(res.data.total / res.data.page_size)
          ) {
            this.showMore = false;
          }
          if (this.page == 1) {
            this.tabMain = res.data.datalist;
          } else {
            this.tabMain = this.tabMain.concat(res.data.datalist);
          }
          if (res.data.label_list.length > 0) {
            this.categoryList = res.data.label_list;
            this.categoryList.unshift({
              id: 0,
              label_name: "全部",
            });
          }
        }
      });
    },
    getMoreList() {
      this.page = this.page + 1;
      this.getSpaceList();
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `/venue-default/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/venue_default_index.scss";
</style>
